@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.logo{
    font-family: Roboto;
    font-weight: 700;
    color: orange;
    font-size: 20px;
    margin: 0px;
    letter-spacing: 1px;
}
.navbar {
  height: 60px;
  background-color: #FFFFFF;
  position: fixed;
  width: 100%;
}

.menu-icon {
  display: none;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul a {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    font-family: calibri;
    color: #2f234f;
    text-decoration: none;
    text-transform: uppercase;
}
.nav-elements li a{
    padding: 10px 15px;
}
.nav-elements li a:hover{
    background-color: #292929;
    color: #FFFFFF !important;
    font-weight: 700;
    transition: all ease 0.4s;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@media(max-width:600px){
    .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    margin: 0px;
    padding: 0px;
    background-color: #fef7e5;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
    overflow: hidden;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
    overflow: hidden;
  }
}