@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&display=swap');


html{
    scroll-behavior: smooth;
}
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
.main1{
    height: 100vh;
}
.main{
    height: 100vh;
    background-image: url("https://images.unsplash.com/photo-1558196068-5c45b2104379?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80");
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
    box-sizing: border-box;
    position: relative;
}
.intro{
    font-family: calibri;
    width: 500px;
    position: absolute;
    left: 20%;
    top: 60%;
    transform: translate(-20%, -50%);
}

.intro h1{
    font-family: Roboto;
    font-weight: 700;
    color: orange;
    font-size: 45px;
    margin: 0px;
    letter-spacing: 5px;
}
.intro h2{
    font-family: Poppins;
    font-weight: 600;
    color: #FFFFFF;
    font-size: 35px;
    margin: 0px;
    letter-spacing: 5px;
    padding-top: 20px;
}
.intro p{
    color: #FFFFFF;
    font-weight: 500;
    font-size: 16px;
    word-spacing: 3px;
    font-family: Lato;
    margin-top: -5px;
}
.intro p span{
    color: #FFFFFF;
}
.learn_more{
    width: 160px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: orange;
    font-weight: 500;
    color: #FFFFFF;
    text-decoration: none;
    
}
.learn_more:hover{
    background-color: rgba(255, 166, 0, 0.904);
    transition: all ease 0.5s;
    cursor: pointer;
}

.about{
    width: 100%;
    height: 40vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 50px 5% 0px 5%;
}
.about_text{
    text-align: center;
}

.about_text h1{
    font-size: 3rem;
    font-family: Poppins;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
}
.about_text p{
    font-size: 14px;
    font-family: Lato;
    width: 800px;
    text-align: center;
    line-height: 1.5;
}
.image_row{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.about img{
    width: 350px;
    height: 300px;
    margin-left: 20px;
    border-radius: 20px;
}
.contact_form{
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
}

.contact_form form{
    display: flex;
    width: 70%;
    height: 64vh;
    background-color: #1a1a1a;
    box-shadow: 2px 12px 20px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.01);
}

.contact_left{
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact_right{
    width: 60%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-left: 1px solid rgba(255, 255, 255, 0.08);
}
.c_l_heading{
    font-family: Lato;
    color: #FFFFFF;
    font-size: 3rem;
    letter-spacing: 2px;
    font-weight: 400;
}
.c_l_heading span{
    border-bottom: 3px solid orange;
}
.f_name,.f_email{
    display: flex;
    flex-direction: column;
    font-family: Lato;
}
.f_email span,
.f_name span{
    color: #bfbfbf;
    font-size: 22px;
}
.f_email input,
.f_name input{
    height: 30px;
    width: 250px;
    border: none;
    outline: none;
    background-color: transparent;
    border-bottom: 1px solid #929292;
    color: #FFFFFF;
    margin: 10px 0px;
}

.f_email input::placeholder,
.f_name input::placeholder{
    opacity: 0.5;
    letter-spacing: 1px;
}

.f_email input:focus,
.f_name input:focus{
    border-bottom: 1px solid orange;
    transition: all ease 0.5s;
}
.message span{
    font-size: 18px;
    font-family: Lato;
    color: #4e4e4e;
}
.message{
    margin: 20px;
    display: flex;
    flex-direction: column;
}

.contact_right textarea{
    width: 100% !important;
    height: 320px !important;
    border: none;
    outline: none;
    background-color: transparent;
    box-sizing: border-box;
    color: #ebebeb;
    font-size: 14px;
}

.contact_right textarea::placeholder{
    color: #ebebeb;
    font-size: 15px;
    letter-spacing: 2px;
    font-family: Lato;
}

.contact_right button{
    width: 100%;
    height: 50px;
    background-color: orange;
    font-weight: bold;
    outline: none;
    border: none;
    margin: 0px;
    color: #1b1b1b;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
}
.contact_us{
    width: 100%;
    height: 80vh;
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-around;
    padding-top: 50px;
}
.c_info{
    display: flex;
    flex-direction: column;
    padding-top: 100px;
}
.c_info h1{
    font-family: Poppins;
    text-transform: capitalize;
    font-size: 60px;
    font-weight: 800;
    line-height: 6px
}

.c_info p{
    font-family: Lato;
    font-weight: 400;
}
.c_info span{
    font-family: Poppins;
    font-weight: bold;
}
.google_map iframe {
     height: 70vh;
     width: 550px;
     left: 0;
     top: 0;
}
footer{
    height: 15vh;
    background-color: black;
}
@media(max-width:1190px){
    .intro{
        left: 40%;
        top: 50%;
        transform: translate(-10%, -50%);
    }
    .about_text{
    text-align: center;
}

.about_text h1{
    font-size: 2.7rem;
    font-family: Poppins;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
}
.about_text p{
    font-size: 14px;
    font-family: Lato;
    width: 800px;
    text-align: center;
    line-height: 1.5;
}
.image_row{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.about img{
    width: 250px;
    height: 200px;
    margin-left: 20px;
    border-radius: 20px;
}
.contact_form form{
    width: 95% !important;
}
.contact_right button{
    margin-top: -100px;
}
.c_info h1{
    font-family: Poppins;
    text-transform: capitalize;
    font-size: 50px;
    font-weight: 800;
    line-height: 6px
}
.c_info{
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}
.c_info p{
    font-family: Lato;
    font-weight: 400;
}
.c_info span{
    font-family: Poppins;
    font-weight: bold;
}
.google_map iframe {
     height: 70vh;
     width: 450px;
     left: 0;
     top: 0;
}
}
@media(max-width:970px){
    .intro{
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .contact_us{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    height: 130vh;
}
.google_map iframe {
     height: 60vh;
     width: 600px;
     left: 0;
     top: 0;
}
}

@media(max-width:900px){

.contact_form{
    height: auto;
    padding: 20px;
    box-sizing: border-box;
}
.contact_form form{
    flex-direction: column;
    height: auto;
    padding: 20px;
    width: 90% !important;
}
.contact_left,
.contact_right{
    width: 100%;
    border: none;
}
.f_email,
.f_name{
    width: 100%;
}
.f_email input,
.f_name input{
    width: 100%;
    border: none;
    background-color: #262626;
    padding: 10px;
    box-sizing: border-box;
    height: 40px;
}
textarea{
    background-color: #262626 !important;
    margin:  10px  0px;
    padding: 10px;
    height: 200px !important;
    width: 100% !important;
}
.about_text p{
    font-size: 14px;
    font-family: Lato;
    width: 600px;
    text-align: center;
    line-height: 1.5;
}
.contact_form form h1{
    margin: 5px 0px;
}
footer{
    display: flex;
    flex-direction: column;
}
footer p,
footer h4{
    padding-left: 30px;
}
}

@media(max-width:800px){
    .image_row{
        display: flex;
        flex-direction: column;
    }
.about span{
    display: none;
}

.about img{
    width: 450px;
    height: 250px;
    margin-left: 20px;
    border-radius: 20px;
}
}
@media(max-width:650px){
.google_map iframe {
     height: 50vh;
     width: 450px;
     left: 0;
     top: 0;
}
.about_text p{
    width: 550px;
}
.about img{
    display: none;
}
.about{
    height: 60vh;
}
}

@media(max-width:570px){
    .about_text p{
    width: 100%;
}
}
@media(max-width:500px){
    .intro h1{
    text-align: center;
    font-size: 40px;
}
.intro p{
    color: #ffffff;
    font-size: 15px;
    word-spacing: 3px;
    font-family: Lato;
    width: 80%;
    font-weight: 400;
}
    .intro{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .about_text p{
    width: 400px;
}
.c_info h1{
    font-family: Poppins;
    text-transform: capitalize;
    font-size: 40px;
    font-weight: 800;
    line-height: 6px
}
.c_info{
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    align-items: center;
    text-align: center;
    width: 80%;
}
.c_info p{
    font-family: Lato;
    font-weight: 400;
}
.c_info span{
    font-family: Poppins;
    font-weight: bold;
}
.google_map iframe {
     height: 50vh;
     width: 400px;
     left: 0;
     top: 0;
}
}